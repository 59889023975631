import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
//import products from './assets/productDetails'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwkufMtVoV3Dti2-Ls9XnsCpm0_pLClPE",
  authDomain: "webstore-c0cbf.firebaseapp.com",
  projectId: "webstore-c0cbf",
  storageBucket: "webstore-c0cbf.appspot.com",
  messagingSenderId: "178506585635",
  appId: "1:178506585635:web:d7fb7c3b439838ec174396",
  measurementId: "G-QESZSJ1H2Y"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
// get docs from Firebase and load data before render
db.collection("products").get()
  .then((store) => {
    Vue.prototype.$productList = store;
    Vue.config.productionTip = false
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  });
