<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HomeElement msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeElement from "@/components/HomeElement.vue";

export default {
  name: "HomeView",
  components: {
    HomeElement,
  },
};
</script>
