<template>
  <div id="app">
    <construction-view />
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/products">Products</router-link>
    </nav>
    <div class="view-container">     
      <router-view />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: azure;
}

nav {
  width: 100%;
  text-align: center;
  padding: 30px;
}

nav a {
  font-weight: bold;
  box-shadow: 3px 3px 3px #00000010, -3px -3px 3px #ffffff3d,
    2px 2px 2px #ffffff6c inset, -2px -2px 2px #00000010 inset;
  border-radius: 5px;
  color: #2c3e50;
  padding: 10px;
}

nav a.router-link-exact-active {
  box-shadow: 5px 5px 5px #00000010 inset, -5px -5px 5px #ffffff6c inset,
    2px 2px 2px #ffffff6c, -2px -2px 2px #00000010;
  border-radius: 5px;
  color: #42b983;
}

.view-container {
  width: 80%;
  text-align: center;
  margin: auto;
}
</style>
 <script>
import ConstructionView from "@/views/ConstructionView.vue"
export default {
  components: {
    ConstructionView
  }
}

 </script>