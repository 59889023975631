<template>
  <form v-on:submit="submitForm">
    <p>admin</p>
    <Label>Name: </Label>
    <input type="text" v-model="name" />
    <br />
    <Label>Price: </Label>
    <input type="text" v-model="price" />
    <br />
    <Label>Description: </Label>
    <input type="text" v-model="description" />
    <br />
    <button
      type="submit"
      class="
        bg-blue-500
        hover:bg-blue-700
        text-white
        font-bold
        py-2
        px-4
        rounded
      "
    >
      add Product
    </button>
    <button
      class="
        bg-blue-500
        hover:bg-blue-700
        text-white
        font-bold
        py-2
        px-4
        rounded
      "
    >
      cancel
    </button>
  </form>
</template>

<script>
export default {
  name: "AdminView",
  components: {},
  data: function () {
    return {
      name: "",
      price: "",
      description: "",
      products: this.$productList,
    };
  },
  methods: {
    submitForm: function (e) {
      e.preventDefault();
      this.products[this.products.length] = {
        id: this.products.length,
        name: this.name,
        description: this.description,
        price: this.price,
      };

      for (var i in this.products) {
        console.log(this.products[i].id);
        console.log(this.products[i].name);
        console.log(this.products[i].description);
        console.log(this.products[i].price);
      }
    },
  },
};
</script>

<style>
button {
  margin: 5px;
}

input {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>