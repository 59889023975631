<template>
  <div class="container" v-on:click="addToCart">
    <div class="tooltip">
      <p>{{ description }}</p>
    </div>
    <h2 class="font-bold">{{ name }}</h2>
    <img src="https://picsum.photos/200" />
    <p class="price">{{ price }}</p>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    name: String,
    description: String,
    price: String,
  },
  mounted() {},
  methods: {
    addToCart: function (event) {
      if (event) {
        console.log(this.price);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container {
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
}

.container:hover .tooltip {
  visibility: visible;
}

.container:hover h2 {
  visibility: hidden;
}

.container:hover img {
  transition: 1s;
  transform: scale(1.25);
}

.tooltip {
  position: absolute;
  visibility: hidden;
  border-radius: 5px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  top: 0;
}

.price {
  position: absolute;
  bottom: 0;
  right: 3px;
  z-index: 1;
}
</style>
