<template>
  <div class="view">
    <h1>Here are the Products</h1>
    <div class="cardContainer">
      <div v-for="product in products" :key="product.name">
        <product-card
          :name="product.data().name"
          :description="product.data().description"
          :price="product.data().price"
        >
        </product-card>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";

export default {
  components: { ProductCard },
  data() {
    return {
      products: this.$productList.docs,
    };
  },
  mounted() {},
};
</script>

<style >
.cardContainer {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 10px 10px;
}

.cardContainer div {
  width: 200px;
  height: 250px;
}
</style>