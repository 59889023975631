<template>
  <div class="construction text-center">
      <img src="../assets/pexels-pixabay-209235.jpg"/>
      <p>We are currently working on this site</p>
  </div>
</template>

<script>
export default {
    name: "ConstructionView"
}
</script>

<style>
.construction{
    width: 100%;
    height: 100%;
    background-color: red;
    position: absolute;
}
img {
    object-fit: cover;
}
</style>